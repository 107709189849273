import React from "react"
import { graphql, Link } from "gatsby"

import styled from 'styled-components';
import Layout from "../components/layouts/courseLandingLayout"
import Navbar from '../components/navbars/courseLandingNavbar';
import SearchEngine from "../components/SearchEngine"
import { Container, Col, Row } from 'react-bootstrap';

const HeroContainer = styled(Container)`
  margin-top: 50px;
`


const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <HeroContainer>
        <SearchEngine title="404: Not Found" />
        <h1>Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist.<span> </span> 
          <Link to='/'>
            Click here to go back home.
          </Link>
        </p>
      </HeroContainer>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
